import { navigate } from 'gatsby';
import { useSubmitWebform } from './useSubmitWebform';
import { useUploadFilesFromForm } from './useUploadFilesFromForm';

export const useHandleSubmitWebform = async (
    e: any,
    id: string,
    language: string,
    confirmationTitle: string,
    requiredRadios: Array<string> | null,
    requiredCheckboxes?: Array<string> | null,
    requiredSelects?: Array<string> | null,
    checkboxes?: any,
    additionalFields?: any,
    redirectUrl?: string,
    files?: Array<any>,
    fieldWithFileName?: string,
    multipleFiles?: number | boolean,
    confirmationMessage?: string,
    extraFields?: any
) => {
    e.preventDefault();
    let submitForm;
    let letFetchData = true;
    const formData = new FormData(document.querySelector(`#${id}`));

    let dataToSend = {};
    for (let pair of formData.entries()) {
        dataToSend[pair[0]] = pair[1];
    }
    if (checkboxes) {
        for (let pair of Object.entries(checkboxes)) {
            dataToSend[pair[0]] = pair[1];
        }
    }
    if (extraFields && Object.keys(extraFields)?.length) {
        dataToSend = { ...dataToSend, ...extraFields };
    }
    if (requiredRadios && Array.isArray(requiredRadios)) {
        requiredRadios.forEach((radio: string) => {
            if (!dataToSend.hasOwnProperty(radio)) {
                letFetchData = false;
                return;
            } else if (dataToSend[radio].length === 0) {
                letFetchData = false;
                return;
            }
        });
    }
    if (requiredCheckboxes && Array.isArray(requiredCheckboxes)) {
        requiredCheckboxes.forEach((checkbox: string) => {
            if (!dataToSend.hasOwnProperty(checkbox)) {
                letFetchData = false;
                return;
            } else if (dataToSend[checkbox].length === 0) {
                letFetchData = false;
                return;
            }
        });
    }
    if (requiredSelects && Array.isArray(requiredSelects) && !(extraFields && Object.keys(extraFields)?.length)) {
        requiredSelects.forEach((select: string) => {
            if (!additionalFields.hasOwnProperty(select)) {
                letFetchData = false;
                return;
            } else if (additionalFields[select].length === 0) {
                letFetchData = false;
                return;
            }
        });
    }
    if (files && files.length > 0 && Array.isArray(files)) {
        submitForm = await useUploadFilesFromForm(
            files,
            id,
            fieldWithFileName,
            Object.assign(dataToSend, additionalFields),
            language,
            multipleFiles
        );
        submitForm = true;
    }

    if (!submitForm && letFetchData) {
        if (additionalFields) {
            if (!(files && files.length > 0)) {
                submitForm = await useSubmitWebform(Object.assign(dataToSend, additionalFields), language);
            }
        } else {
            submitForm = await useSubmitWebform(dataToSend, language);
        }
    }

    if (submitForm === true && letFetchData) {
        if (redirectUrl && redirectUrl.length > 0) {
            navigate(`/${language}${redirectUrl}`);
        } else {
            navigate(`/${language}/webform`, {
                state: {
                    title: confirmationTitle,
                    message: confirmationMessage,
                },
            });
        }
    } else {
        throw new Error();
    }
};
