import axios from 'axios';

export const useSubmitWebform = async (dataToSend: Object, language: string): Promise<any> =>
    await axios({
        method: 'post',
        url: `${process.env.DRUPAL_URL}/${language}/webform_rest/submit`,
        data: dataToSend,
        headers: { 'Content-Type': 'application/json' },
    })
        .then((response) => {
            if (response.status === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch((error) => error);
