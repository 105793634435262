import axios from 'axios';

export const useFetchWebform = async (
    language: string,
    wid: string
): Promise<{ schema: any; submission_link: string; link: string; wid: string; settings: string }> => {
    const data = await axios.post(
        `${process.env.DRUPAL_URL}/graphql`,
        {
            query: `query fetchWebform($language: String!, $wid: String!) {
                        webform(language: $language, wid: $wid) {
                            schema
                            submission_link
                            link
                            wid
                            settings
                        }
                    }
            `,
            variables: {
                language: language,
                wid: wid,
            },
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return {
        schema: data?.data?.data?.webform?.schema ? JSON.parse(data?.data?.data?.webform?.schema) : {},
        submission_link: data?.data?.data?.webform?.submission_link ?? '',
        link: data?.data?.data?.webform?.link ?? '',
        wid: data?.data?.data?.webform?.wid ?? '',
        settings: data?.data?.data?.webform?.settings ? JSON.parse(data?.data?.data?.webform?.settings) : '',
    };
};
