import React, { ChangeEvent, FC, useEffect, useRef } from 'react';
import { CommonRadioAndCheckmarkWrapper, CommonRadio, CommonRadioCheckmark } from '../../../styles/Common';

interface RadioInputInterface {
    setState: Function;
    state: any;
    name: string;
    value: any;
    required?: boolean;
    requiredErrorMessage?: string;
}

const RadioInput: FC<RadioInputInterface> = ({
    setState,
    state,
    name,
    value,
    required = false,
    requiredErrorMessage,
}) => {
    const radioButtonRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        state.hasOwnProperty(name) && radioButtonRef?.current?.setCustomValidity('');
    }, [state, name]);

    return (
        <CommonRadioAndCheckmarkWrapper
            onClick={() => {
                radioButtonRef?.current?.setCustomValidity('');
                setState((state: any) => ({ ...state, [name]: value }));
            }}
        >
            <CommonRadio
                ref={radioButtonRef}
                type="radio"
                name={name}
                value={value}
                checked={state[name] === value}
                onChange={() => {}}
                required={required}
                onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                    requiredErrorMessage && e.target.setCustomValidity(requiredErrorMessage)
                }
            />
            <CommonRadioCheckmark checked={state[name] === value} />
        </CommonRadioAndCheckmarkWrapper>
    );
};

export default RadioInput;
