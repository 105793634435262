import { Link } from 'gatsby';
import styled from 'styled-components';
import {
    blueIcon,
    darkGreen,
    lightGraishButton,
    lightGray,
    lightGreyButton,
    limaBackground,
    white,
    blue,
    greyText,
    snowBackground,
    greenPrintDarker,
    greenProduct,
    greenLightest,
    greyLightBorder,
    greyLightButton,
    greenPrint,
    greyInput,
    containerWidth,
    lightGrayishBlue,
    greyLight,
    citronBackground,
} from './Variables';
import { deviceMax } from './MediaQuery';
import grippie from '../static/images/inputs/grippie.png';
import selectDivider from '../static/images/landingPage/divider.png';
import selectArrow from '../static/images/landingPage/arrow_dropdown.png';
import { BsChevronRight, BsChevronLeft, BsSearch } from 'react-icons/bs';

interface EditableWidthAndHeightInterface {
    width?: string;
    height?: string;
    readonly isWebform?: boolean;
}

export const ContainerColumn = styled.div`
    max-width: ${containerWidth};
    display: flex;
    flex-direction: column;
`;

export const ContainerColumnCenter = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const ContainerCommon = styled.div`
    max-width: ${containerWidth};
    margin: 3rem auto;

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const ContainerCommonWithoutMargin = styled.div`
    max-width: ${containerWidth};
    margin: 0 auto;

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const ContainerColumnStart = styled.div`
    max-width: ${containerWidth};
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`;

export const SmallContainerColumn = styled.div`
    width: 66%;
    display: flex;
    flex-direction: column;
`;

export const ContainerColumnWithoutWidth = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

export const ContainerColumnStartWithoutWidth = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const SectionHeadline = styled.h3`
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    margin: 6px 0;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, sans-serif;
`;

export const SectionTitleLink = styled(Link)`
    color: ${blueIcon};
    text-decoration: none;
    cursor: pointer;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, sans-serif;
`;

export const SectionTitle = styled.h2`
    color: ${blueIcon};
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
    letter-spacing: 0.01em;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, sans-serif;
`;

export const LinkButton = styled(Link)`
    font-size: 0.875rem;
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    color: ${darkGreen};
    border: 1px solid ${lightGray};
    padding: 0.75em 1.875em;
    border-radius: 5px;
    cursor: pointer;
    margin: 2rem 0;
    text-decoration: none;
`;

export const CommonBullets = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
`;

interface BulletProps {
    readonly isActive: boolean;
}

export const CommonBullet = styled.span<BulletProps>`
    background: ${(props) => (props.isActive ? darkGreen : white)};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 2px;
    cursor: pointer;
    box-shadow: inset 0 0 3px rgb(0 0 0 / 30%);
`;

export const GrayBackground = styled.div`
    background-color: ${greyLight};
`;

export const CommonH3 = styled.h3`
    font-family: 'Helvetica Neue LT W02_57 Cond', Helvetica, Arial;
    font-size: 1.5rem;
    color: ${blue};
    margin-bottom: 0.4rem;
`;

export const Center = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const CommonInput = styled.input<EditableWidthAndHeightInterface>`
    width: ${(props) => (props.width ? props.width : '100%')};
    border: 1px solid ${lightGray};
    border-radius: 5px;
    height: ${(props) => (props.height ? props.height : '2.625rem')};
    color: ${greyText};
    text-indent: 20px;

    &:focus {
        outline: 0;
        cursor: pointer;
    }
`;

interface CommonRadioInterface {
    readonly checked?: boolean;
}

export const CommonRadioCheckmark = styled.span<CommonRadioInterface>`
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #f8f9fa;
    border: 1px solid ${greyLightBorder};
    border-radius: 50%;

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background: ${(props) =>
            props.checked ? 'linear-gradient(to bottom,#94d232 0%,#8bba2b 100%) !important' : '#dbdbdb'};
        opacity: ${(props) => (props.checked ? '1 !important' : '1')};
    }

    &:hover {
        &:after {
            background: linear-gradient(to bottom, #94d232 0%, #8bba2b 100%);
            opacity: 0.3;
        }
    }
`;

export const CommonRadio = styled.input`
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    cursor: pointer;
`;

export const CommonRadioAndCheckmarkWrapper = styled.div`
    display: block;
    position: relative;
    cursor: pointer;
    height: 1.625rem;
    width: 1.625rem;
`;

export const CommonTextArea = styled.textarea<EditableWidthAndHeightInterface>`
    border-radius: 5px;
    border: 1px solid ${lightGray};
    position: relative;
    height: ${(props) => (props.height ? props.height : '7.125rem')};
    width: ${(props) => (props.width ? props.width : '100%')};
    margin: 0;
    box-sizing: border-box;

    &:focus {
        outline: 0;
        cursor: pointer;
    }
`;

export const Grippie = styled.div<EditableWidthAndHeightInterface>`
    margin-top: -6px;
    background: #eee url(${grippie}) no-repeat center 2px;
    height: 9px;
    cursor: s-resize;
    width: ${(props) => (props.width ? props.width : '100%')};
    border: 1px solid #ddd;
    border-top-width: 0;
    overflow: hidden;
    box-sizing: border-box;
`;

interface CommonSelectInterface extends EditableWidthAndHeightInterface {
    readonly error?: boolean;
}

export const CommonSelect = styled.select<CommonSelectInterface>`
    max-width: 100%;
    background: url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
        linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%);
    border: ${(props) => (props.error ? '1px solid red' : `1px solid ${lightGray}`)};
    cursor: pointer;
    padding-left: 0.8rem;
    width: ${(props) => (props.width ? props.width : '17rem')};
    height: ${(props) => (props.height ? props.height : '2.5rem')};
    color: ${darkGreen};
    border-radius: 5px;
    appearance: none;
    outline: none;

    option {
        color: ${darkGreen};
    }
`;

export const CommonSelectWithHoverAndFocus = styled(CommonSelect)`
    &:hover {
        background: url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
            linear-gradient(to right, rgba(0, 0, 0, 0) 84%, ${lightGrayishBlue} 84%),
            linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%);
        color: ${greenPrintDarker};
    }

    &:active {
        background: url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
            linear-gradient(to right, rgba(0, 0, 0, 0) 84%, #e4e5e7 84%),
            linear-gradient(360deg, #fafbfc 30%, #eff2f5 90%);
        option {
            background: ${white};
        }
    }
`;

interface SelectOptionAndInputWrapperInterface {
    readonly isActive: boolean;
    readonly width?: string;
    readonly height?: string;
    readonly error?: boolean;
}

export const SelectWithSearchInput = styled.div<SelectOptionAndInputWrapperInterface>`
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    justify-content: flex-start;
    align-items: center;
    background: ${(props) =>
        props.isActive
            ? `url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
            linear-gradient(to right, rgba(0, 0, 0, 0) 84%, #e4e5e7 84%),
            linear-gradient(360deg, #fafbfc 30%, #eff2f5 90%)`
            : `url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
            linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%)`};
    border: ${(props) => (props.error ? `1px solid red` : `1px solid ${lightGray}`)};
    cursor: pointer;
    padding-left: 0.8rem;
    width: ${(props) => (props.width ? props.width : '13rem')};
    height: ${(props) => (props.height ? props.height : '2.5rem')};
    color: ${darkGreen};
    border-radius: 5px;
    appearance: none;
    outline: none;
    margin: 0;
    box-sizing: border-box;

    &:hover {
        background: ${(props) =>
            props.isActive
                ? `url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
            linear-gradient(to right, rgba(0, 0, 0, 0) 84%, #e4e5e7 84%),
            linear-gradient(360deg, #fafbfc 30%, #eff2f5 90%)`
                : `url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
            linear-gradient(to right, rgba(0, 0, 0, 0) 84%, ${lightGrayishBlue} 84%),
            linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%)`};
        color: ${greenPrintDarker};
    }
`;

export const SelectOptionAndInputWrapper = styled.div<SelectOptionAndInputWrapperInterface>`
    ${(props) =>
        props.isActive
            ? {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
              }
            : {
                  display: 'none',
              }}
    position: absolute;
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : '13.8rem')};
    max-width: ${(props) => (props.width ? props.width : '13.8rem')};
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid ${lightGray};
    border-radius: 3px;
    background-color: ${white};
`;

export const SelectSearchInput = styled.input`
    width: 90%;
    margin-top: 5px;
    outline: none;
    border: 1px solid ${lightGray};
`;

export const SelectOptionWrapper = styled.ul`
    width: 100%;
    max-height: 240px;
    overflow: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    z-index: 1;

    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
`;

export const SelectOption = styled.li`
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-family: Arial, Helvetica, sans-serif;
    color: ${darkGreen};
    font-weight: 200;
    word-break: break-all;
    border-bottom: 1px solid ${lightGray};
    cursor: pointer;
    line-height: 3em;
    padding: 5px 0 5px 1.25rem;
    background-color: ${white};

    &:hover {
        background: ${snowBackground};
        color: ${greenPrintDarker};
    }
`;

export const SelectOptionError = styled(SelectOption)`
    cursor: default;
`;

export const FileInputWrapper = styled.input<EditableWidthAndHeightInterface>`
    max-width: 100%;
    box-sizing: border-box;
    border: 1px solid ${lightGray};
    border-radius: 5px;
    height: ${(props) => (props.height ? props.height : '2.625rem')};
    color: ${darkGreen};
    text-indent: 20px;
    background: transparent linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%) repeat scroll 0% 0%;
    width: ${(props) => (props.width ? props.width : '100%')};
    font-size: 15px;
    margin-bottom: 15px;
`;

interface FileInputParagraph {
    readonly information?: boolean;
}

export const FileInputParagraph = styled.p<FileInputParagraph>`
    font-size: 0.85rem;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: ${(props) => (props.information ? '0' : '10px')};
    margin: 0;

    span {
        font-size: 0.85rem;
        font-family: Arial, Helvetica, sans-serif;
        color: ${blueIcon};
        cursor: pointer;
    }
`;

export const SendButtonGreenGradient = styled.button<EditableWidthAndHeightInterface>`
    border: 1px solid ${lightGray};
    border-radius: 5px;
    color: ${white};
    width: ${(props) => (props.width ? props.width : '15rem')};
    font-size: 0.875rem;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(${limaBackground}, ${citronBackground}) repeat scroll 0 0 transparent;
    padding-block: 0.65rem;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        background: ${greenPrint};
    }

    &:disabled {
        background: ${greyInput};
        color: ${greyText};
        cursor: auto;
    }

    ${(props) =>
        props.isWebform &&
        `
        @media ${deviceMax.mobileM} {
            width: 100%;
        }
    `}
`;

export const SendButtonGreen = styled.button<EditableWidthAndHeightInterface>`
    background-color: ${limaBackground};
    color: ${white};
    font-weight: 600;
    border-radius: 5px;
    width: ${(props) => (props.width ? props.width : '9rem')};
    text-align: center;
    padding-block: 0.55rem;
    cursor: pointer;
    font-size: 0.9375rem;
    border: none;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        opacity: 0.7;
    }
`;

interface SendButtonGreyGradient {
    readonly width?: string;
    readonly hoverEffect?: boolean;
}

export const SendButtonGreyGradient = styled.button<SendButtonGreyGradient>`
    border: 1px solid ${lightGray};
    border-radius: 5px;
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    text-align: center;
    padding: 0.625rem 2rem;
    color: ${darkGreen};
    font-size: 0.875rem;
    float: left;
    bottom: 20px;
    cursor: pointer;
    width: ${(props) => (props.width ? props.width : 'unset')};
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        background: ${(props) =>
            props.hoverEffect ? lightGrayishBlue : `linear-gradient(${lightGreyButton}, ${lightGraishButton})`};
    }
`;

export const ButtonWithLinkGreyGradient = styled(Link)<SendButtonGreyGradient>`
    border: 1px solid ${lightGray};
    border-radius: 5px;
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    text-align: center;
    padding: 12px 30px;
    color: ${darkGreen};
    font-size: 0.875rem;
    float: left;
    bottom: 20px;
    cursor: pointer;
    width: ${(props) => (props.width ? props.width : 'unset')};
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        background: ${lightGrayishBlue};
    }
`;

export const CommonCheckboxWrapper = styled.div`
    display: block;
    position: relative;
    cursor: pointer;
    height: 1.625rem;
    width: 1.625rem;
`;

interface CheckBoxInterface {
    readonly isActive: boolean;
}

export const CommonCheckboxCheckmark = styled.div<CheckBoxInterface>`
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid ${greyLightBorder};
    background: linear-gradient(to bottom, ${greyLightButton} 0%, #fafbfc 40%, #f6f8fa 100%);

    &::before {
        content: '';
        position: absolute;
        width: 0.75rem;
        height: 0.75rem;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        background-color: ${(props) =>
            props.isActive
                ? `${greenProduct} !important`
                : `linear-gradient(to bottom,${greyLightButton} 0%,#fafbfc 40%,#f6f8fa 100%)`};
    }

    &:hover {
        &::before {
            background-color: ${greenLightest};
        }
    }
`;

export const CommonCheckBox = styled.input`
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    cursor: pointer;
`;

export const SelectWrapper = styled.div<SelectOptionAndInputWrapperInterface>`
    width: ${(props) => (props.width ? props.width : '13rem')};
`;

export const WebformConfirmationContainer = styled(ContainerColumn)`
    margin: 0 auto;
`;

export const WebformConfirmationTitle = styled.h1`
    color: ${blue};
    font-size: 2.5rem;
    letter-spacing: 0.025em;
    line-height: 2.75rem;
    margin-bottom: 17px;
`;

export const WebformConfirmationMessage = styled.p`
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
`;

interface SliderNavigationArrowWrapperProps {
    readonly isHighlighted?: boolean;
}

const SliderNavigationArrowWrapper = styled.div<SliderNavigationArrowWrapperProps>`
    border-radius: 50%;
    width: 3em;
    height: 3em;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${(props) => (props.isHighlighted ? '0' : '1em')};
    position: absolute;
    top: 40%;
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.3);
`;

export const LeftArrowWrapper = styled(SliderNavigationArrowWrapper)`
    left: 0;

    ${(props) =>
        props.isHighlighted &&
        `
        @media ${deviceMax.tablet} {
            left: 16px;
        }
    `}
`;

export const RightArrowWrapper = styled(SliderNavigationArrowWrapper)`
    right: 0;

    ${(props) =>
        props.isHighlighted &&
        `
        @media ${deviceMax.tablet} {
            right: 16px;
        }
    `}
`;

export const LeftArrow = styled(BsChevronLeft)`
    fill: ${white};
    font-size: 1.5em;
`;

export const RightArrow = styled(BsChevronRight)`
    fill: ${white};
    font-size: 1.5em;
`;

export const Title = styled.h1`
    color: ${blue};
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 44px;
    margin-bottom: 17px;
`;

export const SearchIcon = styled(BsSearch)`
    fill: ${darkGreen};
    position: absolute;
    right: 10px;
    cursor: pointer;
`;

export const SelectDivider = styled.div`
    height: 85%;
    width: 1px;
    background: url(${selectDivider});
    position: absolute;
    right: 2.1em;
`;
