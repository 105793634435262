import styled, { css } from 'styled-components';
import { INVISIBLE, TITLE_MAIN } from '../../constants/commonConstants';
import { CommonInput, ContainerColumnStartWithoutWidth, SectionHeadline, SectionTitle } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { blue, blueIcon } from '../../styles/Variables';

export const ContactContainer = styled(ContainerColumnStartWithoutWidth)`
    width: 38.75rem;
    margin: 0 auto;

    @media ${deviceMax.tablet} {
        width: 90%;
    }
`;

export const Title = styled(SectionTitle)`
    font-size: 2.5rem;
    margin: 2.75rem 0 2rem 0;
    line-height: 2.75rem;
    letter-spacing: 0.05em;
`;

export const Text = styled.div`
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 1.5rem;

    * {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 1.5rem;
    }
`;

export const Description = styled(Text)<ElementDisplayInterface>`
    display: ${({ display }) => (display === INVISIBLE ? 'none' : 'block')};
    margin-top: -10px;
`;

export const RadioBoxContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const RadioLabel = styled.label`
    margin-left: 1rem;
    font-size: 0.875rem;
    line-height: 0.875rem;

    div {
        font-size: 0.875rem;
        line-height: 0.875rem;
    }

    a {
        text-decoration: none;
        color: ${blue};
        margin: 0 2px;
    }

    * {
        font-family: Arial, Helvetica, sans-serif;
    }
`;

interface FormSectionWrapperInterface {
    readonly deleteMarginBottom?: boolean;
}

export const FormSectionWrapper = styled.div<FormSectionWrapperInterface>`
    margin: 0;
    padding: 0;
    margin-bottom: ${(props) => (props.deleteMarginBottom ? '0' : '1.5rem')};
`;

export const InputSectionWrapper = styled.div`
    margin: 0;
    padding: 0;

    &:last-child {
        input {
            margin-bottom: 0;
        }
    }
`;

interface FormSectionTitleInterface {
    readonly redColor?: boolean;
    readonly display?: string;
    readonly titleType?: string;
}

export const FormSectionTitle = styled(SectionTitle)<FormSectionTitleInterface>`
    margin-bottom: 1.5rem;
    color: ${(props) => (props.redColor ? 'red' : 'black')};
    padding-top: ${(props) => (props.redColor ? '1rem' : 'unset')};
    width: ${(props) => (props.redColor ? '100%' : 'unset')};
    display: ${({ display }) => (display === INVISIBLE ? 'none' : 'block')};

    ${({ titleType }) =>
        titleType === TITLE_MAIN
            ? css`
                  color: ${blueIcon};
              `
            : css`
                  font-size: 1.25rem;
              `};

    span {
        color: #f00;
        font-weight: 700;
    }
`;

interface ElementDisplayInterface {
    readonly display?: string;
}

export const FormSectionHeadline = styled(SectionHeadline)<ElementDisplayInterface>`
    text-transform: none;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    display: ${({ display }) => (display === INVISIBLE ? 'none' : 'block')};
    margin-top: 0;
    margin-bottom: 1.5rem;

    span {
        color: #f00;
        font-weight: 700;
    }
`;

export const InputSectionHeadline = styled(FormSectionHeadline)`
    margin: 0;
    padding: 0;

    * {
        margin: 0;
        padding: 0;
    }
`;

export const TextInput = styled(CommonInput)`
    margin-bottom: 15px;

    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;
