import axios from 'axios';
import { useSubmitWebform } from './useSubmitWebform';

export const useUploadFilesFromForm = async (
    files: Array<any>,
    id: string,
    fieldWithFileName: string,
    dataToSend: Object,
    language: string,
    multipleFiles: number | boolean
) => {
    const fids: Array<string> = [];
    const prepareFids = (fids: Array<string>): string | Array<string> => {
        if (fids.length > 0) {
            if (multipleFiles === 1) {
                return fids[0];
            } else {
                return fids;
            }
        }
        return [];
    };
    const sendFile = async (fileNumber: number) => {
        await axios({
            method: 'post',
            url: `${process.env.DRUPAL_URL}/webform_rest/${id}/upload/${fieldWithFileName}?_format=json`,
            data: files[fileNumber],
            headers: {
                'Content-Type': 'application/octet-stream',
                'Content-Disposition': 'file; filename="' + files[fileNumber].name + '"',
            },
        }).then((response) => {
            if (response.status === 201 && response.data?.fid?.length > 0) {
                fids.push(response.data.fid[0].value);
                if (multipleFiles) {
                    if (Number.isNaN(multipleFiles)) {
                        if (files.length - 1 > fileNumber && files.length < multipleFiles) {
                            sendFile(++fileNumber);
                        } else {
                            return useSubmitWebform(
                                Object.assign(dataToSend, { bewerbungsdateien: prepareFids(fids) }),
                                language
                            );
                        }
                    } else {
                        if (files.length - 1 > fileNumber) {
                            sendFile(++fileNumber);
                        } else {
                            return useSubmitWebform(
                                Object.assign(dataToSend, { bewerbungsdateien: prepareFids(fids) }),
                                language
                            );
                        }
                    }
                }
            } else {
                return false;
            }
        });
    };
    return sendFile(0);
};
