import React, { useRef, useEffect, FC, ChangeEvent } from 'react';
import { CommonTextArea, Grippie } from '../../../styles/Common';

interface TextAreaWithGrippieInterface {
    width?: string;
    height?: string;
    name?: string;
    required?: boolean;
    requiredErrorMessage?: string;
}

const TextAreaWithGrippie: FC<TextAreaWithGrippieInterface> = ({
    width = '100%',
    height = '114px',
    name,
    required,
    requiredErrorMessage,
}) => {
    const textareaRef = useRef(null);
    const draggableDiv = useRef(null);

    useEffect(() => {
        let staticOffset = 0;
        const performDrag = (e: any) => {
            if (textareaRef?.current) {
                textareaRef.current.style.height = `${Math.max(32, staticOffset + e.pageY)}px`;
                return false;
            }
        };

        const endDrag = (): void => {
            if (textareaRef?.current) {
                textareaRef.current.style.opacity = '1';
            }
            if (typeof document !== 'undefined') {
                document.removeEventListener('mousemove', performDrag);
                document.removeEventListener('mouseup', endDrag);
            }
        };

        const startDrag = (e: any): boolean => {
            if (textareaRef?.current) {
                staticOffset = textareaRef.current.clientHeight - e.pageY;
                textareaRef.current.style.opacity = '0.25';
            }
            if (typeof document !== 'undefined') {
                document.addEventListener('mousemove', performDrag);
                document.addEventListener('mouseup', endDrag);
            }
            return false;
        };
        draggableDiv?.current && draggableDiv.current.addEventListener('mousedown', startDrag);
        return () => {
            draggableDiv?.current && draggableDiv.current.removeEventListener('mousedown', startDrag);
        };
    }, []);

    return (
        <>
            <CommonTextArea
                name={name}
                ref={textareaRef}
                width={width}
                height={height}
                required={required}
                onInvalid={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    requiredErrorMessage && e.target.setCustomValidity(requiredErrorMessage)
                }
                onInput={(e: ChangeEvent<HTMLTextAreaElement>) => e.target.setCustomValidity('')}
            />
            <Grippie ref={draggableDiv} width={width} height={height}></Grippie>
        </>
    );
};

export default TextAreaWithGrippie;
