import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import {
    ContactContainer,
    Description,
    FormSectionTitle,
    FormSectionWrapper,
    InputSectionHeadline,
    InputSectionWrapper,
    RadioBoxContainer,
    RadioLabel,
    Text,
    TextInput,
    Title,
} from './ContactUkStyle';
import Header from '../HeaderDynamicPages/Header';
import RadioInput from '../Inputs/RadioInput/RadioInput';
import { useFetchWebform } from '../../hooks/useFetchWebform';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { CONTACT_UK, CONTACT_UK_TITLE, CONTACT_USA, CONTACT_USA_TITLE } from '../../constants/webformsName';
import { SendButtonGreenGradient } from '../../styles/Common';
import TextAreaWithGrippie from '../Inputs/TextAreaWithGrippie/TextAreaWithGrippie';
import ContactData from '../Contact/ContactData/ContactData';
import { useHandleSubmitWebform } from '../../hooks/useHandleSubmitWebform';
import contactTranslations from '../../JSON/StaticSitesTranslations/contactTranslations.json';
import { getSiteName } from '../../utils/getSiteName';
import {
    DESCRIPTION,
    DESCRIPTION_DISPLAY,
    FIELDSET,
    OPTIONS,
    PLACEHOLDER,
    RADIOS,
    REQUIRED,
    REQUIRED_ERROR,
    TEXTAREA,
    TEXTFIELD,
    TITLE,
    TITLE_DISPLAY,
    TITLE_TYPE,
    TYPE,
} from '../../constants/commonConstants';
import { EN, EN_US } from '../../constants/languageCode';
import { greyBackground } from '../../styles/Variables';

interface WebformInterface {
    readonly schema?: Array<any>;
    readonly wid?: string;
    readonly submission_link?: string;
    readonly link?: string;
}

interface WebformInfo {
    readonly id: string;
    readonly title: string;
}

const ContactUk = () => {
    const { language } = useI18next();
    const { t } = useTranslation();
    const [webform, setWebform] = useState<WebformInterface>({});
    const [radios, setRadios] = useState({});
    const KEY = 0;
    const VALUE = 1;

    const webformInfo: WebformInfo =
        language == EN_US
            ? {
                  id: CONTACT_USA,
                  title: CONTACT_USA_TITLE,
              }
            : {
                  id: CONTACT_UK,
                  title: CONTACT_UK_TITLE,
              };

    const getWebform = async (): Promise<void> => {
        //TODO when be fix problem with languages, change here and pass language instead of 'en'
        const { schema, submission_link, link, wid } = await useFetchWebform(EN, webformInfo.id);
        setWebform({ schema, submission_link, link, wid });
    };

    useEffect(() => {
        getWebform();
    }, []);

    const displayCorrectField = (item: any, key: number): ReactNode => {
        switch (item[VALUE][TYPE]) {
            case FIELDSET:
                if (item[VALUE].hasOwnProperty(DESCRIPTION)) {
                    return <Text key={key} dangerouslySetInnerHTML={{ __html: item[VALUE][DESCRIPTION] }} />;
                } else {
                    return (
                        <FormSectionWrapper key={key}>
                            {item[VALUE].hasOwnProperty(TITLE) && (
                                <FormSectionTitle
                                    titleType={item[VALUE][TITLE_TYPE]}
                                    display={item[VALUE][TITLE_DISPLAY]}
                                >
                                    {item[VALUE][TITLE]}
                                </FormSectionTitle>
                            )}
                            {Object?.entries(item[VALUE])?.map((item: any, index: number) => {
                                if (item.length > 1) {
                                    switch (item[VALUE][TYPE]) {
                                        case TEXTFIELD:
                                            return (
                                                <InputSectionWrapper key={`${item[KEY]}${index}`}>
                                                    <InputSectionHeadline display={item[VALUE][TITLE_DISPLAY]}>
                                                        {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                                                        {item[VALUE].hasOwnProperty(REQUIRED) &&
                                                            item[VALUE][REQUIRED] === true && <span>*</span>}
                                                    </InputSectionHeadline>
                                                    <TextInput
                                                        key={`${item[KEY]}${index}`}
                                                        name={item[KEY]}
                                                        placeholder={
                                                            item[VALUE].hasOwnProperty(REQUIRED) &&
                                                            item[VALUE].hasOwnProperty(PLACEHOLDER) &&
                                                            item[VALUE][REQUIRED] === true
                                                                ? `${item[VALUE][PLACEHOLDER]}*`
                                                                : item[VALUE][PLACEHOLDER]
                                                        }
                                                        required={
                                                            item[VALUE].hasOwnProperty(REQUIRED) &&
                                                            item[VALUE][REQUIRED]
                                                        }
                                                        onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                                                            item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                                                            item[VALUE][REQUIRED_ERROR] &&
                                                            e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                                                        }
                                                        onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                                            e.target.setCustomValidity('')
                                                        }
                                                    />
                                                    {item[VALUE].hasOwnProperty(DESCRIPTION) && (
                                                        <Description
                                                            key={index}
                                                            dangerouslySetInnerHTML={{
                                                                __html: item[VALUE][DESCRIPTION],
                                                            }}
                                                            display={item[VALUE][DESCRIPTION_DISPLAY]}
                                                        ></Description>
                                                    )}
                                                </InputSectionWrapper>
                                            );
                                        case TEXTAREA:
                                            return (
                                                <TextAreaWithGrippie
                                                    key={`${item[KEY]}${index}`}
                                                    name={item[KEY]}
                                                    required={item[VALUE][REQUIRED]}
                                                    requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                                                />
                                            );
                                        default:
                                            return null;
                                    }
                                }
                            })}
                        </FormSectionWrapper>
                    );
                }
            case RADIOS:
                const radioName = item[KEY];
                return (
                    <FormSectionWrapper key={key}>
                        <FormSectionTitle display={item[VALUE][TITLE_DISPLAY]}>
                            {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                            {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true && <span>*</span>}
                        </FormSectionTitle>
                        {item[VALUE].hasOwnProperty(OPTIONS) &&
                            Object.entries(item[VALUE][OPTIONS])?.map((item: any, index: number) => {
                                return (
                                    <RadioBoxContainer key={`${item[KEY]}${index}`}>
                                        <RadioLabel htmlFor={radioName}>
                                            <div dangerouslySetInnerHTML={{ __html: item[VALUE] }}></div>{' '}
                                        </RadioLabel>
                                        <RadioInput
                                            state={radios}
                                            setState={setRadios}
                                            name={radioName}
                                            value={item[KEY]}
                                            required={item[VALUE][REQUIRED]}
                                            requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                                        />
                                    </RadioBoxContainer>
                                );
                            })}
                    </FormSectionWrapper>
                );
            default:
                return null;
        }
    };

    const displayForm = (): ReactNode => (
        <form
            id={webformInfo.id}
            style={{ backgroundColor: greyBackground, padding: 25 }}
            onSubmit={(e) => useHandleSubmitWebform(e, webformInfo.id, language, webformInfo.title, null)}
        >
            {webform.hasOwnProperty('schema') &&
                Object.entries(webform.schema)?.map((item: any, index: number) => displayCorrectField(item, index))}
            <input type="hidden" name="webform_id" value={webform?.wid} />
            <SendButtonGreenGradient width="11rem" type="submit">
                Submit
            </SendButtonGreenGradient>
        </form>
    );

    return (
        <Layout title={`${getSiteName(language)} | ${t('contact')}`} languageVersionsUrl={contactTranslations}>
            <Header item={t('contact')} />
            <ContactContainer>
                <Title>Contact</Title>
                {webform.hasOwnProperty('schema') && displayForm()}
                <FormSectionWrapper>
                    <ContactData />
                </FormSectionWrapper>
            </ContactContainer>
        </Layout>
    );
};

export default ContactUk;
