import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import ContactUk from '../components/ContactUk/ContactUk';
import Contact from '../components/Contact/Contact';
import Alternate from '../components/Seo/Alternate';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { staticPageRedirection } from '../utils/staticPagesRedirection';
import { prepareAlternateData } from '../utils/prepareAlternateData';
import { CONTACT_US_LANGAUGES } from '../constants/sitesLanguagesGroup';
import { EN, EN_AU, EN_IN, EN_SG, EN_US, ES_US } from '../constants/languageCode';
import { ContactPartnerExample } from '../templateObjects/internationalContact';

const ContactUsPage = ({
    data: {
        drupal: {
            contact_partners: { data: partnersData },
        },
    },
}) => {
    const { language } = useI18next();
    const languagesWithUrls = [];
    process.env.LANGUAGES.split(',').forEach((language: string) =>
        languagesWithUrls.push(prepareAlternateData(language, 'contact'))
    );
    const commonContactLanguages = [EN, EN_AU, EN_IN, EN_SG, ES_US];

    useEffect(() => {
        staticPageRedirection(language, 'contact', CONTACT_US_LANGAUGES);
    }, []);

    return commonContactLanguages.includes(language) ? (
        <>
            <Alternate languages={languagesWithUrls} />
            <Contact contactPartner={partnersData !== null ? JSON.parse(partnersData) : ContactPartnerExample} />
        </>
    ) : (
        <>
            <Alternate languages={languagesWithUrls} />
            <ContactUk />
        </>
    );
};

export default ContactUsPage;

export const query = graphql`
    query ($language: String!) {
        drupal {
            contact_partners {
                data
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
